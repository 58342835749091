<template>
    <div class="custom-access-type-array">
        <table>
            <thead>
                <tr>
                    <th class="activity-name">
                        {{ $t("vigik.custom.activityName") }}
                    </th>
                    <th class="check">
                        {{ $t("vigik.custom.activityStatus") }}
                    </th>
                    <th class="number">
                        {{ $t("vigik.custom.activityTimeRange") }}
                    </th>
                    <th class="mobile">
                        {{ $t("vigik.custom.provider") }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="activities.length > 0">
                <tr
                    v-for="(activity, index) in activities"
                    :key="`activity-table-${index}`"
                >
                    <td
                        @click="activityProvider(activity)"
                        class="activity-name"
                    >
                        {{ activity.label }} <i class="fa fa-eye"></i>
                    </td>
                    <td class="check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            :id="`allow-${index}`"
                            v-model="activity.allow"
                            @change="changedAllow(activity, false)"
                        />
                        <p
                            :class="activity.allow ? 'allow' : null"
                            @click="changedAllow(activity, true)"
                        >
                            {{ $t("vigik.custom.services") }}
                        </p>
                    </td>
                    <td class="number" @click="activitySchedule(activity)">
                        <span v-if="activity.initialAllow">
                            <Tooltip
                                :text="$t('vigik.custom.timetablesCustomised')"
                            >
                                <i
                                    v-if="
                                        activity.schedule_id !== null &&
                                        activity.schedule_id
                                    "
                                    class="fas fa-clock"
                                    style="color: #ef7b1dff"
                                ></i>
                            </Tooltip>
                            <Tooltip
                                :text="$t('vigik.custom.timetablesDefault')"
                            >
                                <i
                                    v-if="
                                        activity.allow && !activity.schedule_id
                                    "
                                    class="fas fa-pen"
                                    style="color: #53a6bb"
                                />
                            </Tooltip>
                        </span>
                    </td>
                    <td class="mobile">
                        <div class="providerInformations">
                            <BasicChip
                                v-if="activity.blacklists > 0"
                                :text="
                                    $t('vigik.global.blacklisted', {
                                        count: activity.blacklists,
                                    })
                                "
                                pinColor="blue"
                                font="roman"
                            />
                            <BasicChip
                                v-if="activity.whitelists > 0"
                                pinColor="orange"
                                :text="
                                    $t('vigik.global.whitelisted', {
                                        count: activity.whitelists,
                                    })
                                "
                                font="roman"
                            />
                        </div>
                        <div
                            class="providerInformations"
                            v-if="
                                activity.blacklists === 0 &&
                                activity.whitelists === 0 &&
                                activity.allow === 1
                            "
                        >
                            <p>{{ $t("vigik.global.allAutorised") }}</p>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="isSearchText" class="no-activities">
                <tr>
                    <td colspan="4">
                        {{ $t("global.array.noSearchData") }}
                    </td>
                </tr>
            </tbody>
            <tbody v-else class="no-activities">
                <tr>
                    <td colspan="4">
                        {{ $t("global.array.emptyList") }}
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination
            :pagesCount="pagesCount"
            :page="actualPage"
            v-on:new-current-page="(e) => (this.actualPage = e)"
        >
        </pagination>
    </div>
    <div v-if="loading" class="block-loader">
        <Loader />
    </div>
</template>

<script>
import BasicChip from "@/components/basic/BasicChip.vue"
import Pagination from "@/components/basic/Pagination"
import Loader from "@/components/basic/Loader"
import Tooltip from "@/components/basic/Tooltip.vue"

export default {
    name: "CustomAccessType",
    emits: ["activityProvider", "activitySchedule", "changedAllow"],
    components: {
        BasicChip,
        Pagination,
        Tooltip,
        Loader,
    },
    data() {
        return {
            actualPage: 1,
        }
    },
    watch: {
        currentPage: {
            immediate: true,
            handler(value) {
                this.actualPage = value
            },
        },
    },
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        pagesCount: {
            type: Number,
            default: 0,
        },
        checkOne: {
            type: Boolean,
            default: false,
        },
        activities: {
            type: Array,
            default: () => [],
        },
        initialArray: {
            type: Array,
            default: () => [],
        },
        accessModePriority: {
            type: String,
            default: null,
        },
        data: {
            type: Boolean,
            default: false,
        },
        accessTypeName: {
            type: String,
            default: null,
        },
        changeAccessTypeName: {
            type: Boolean,
            default: false,
        },
        newAccessTypeName: {
            type: String,
            default: null,
        },
        keyPressed: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        previousSearchText: {
            type: String,
            default: null,
        },
        addNumber: {
            type: Array,
            default: () => [],
        },
        delNumber: {
            type: Array,
            default: () => [],
        },
        isSearchText: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        activityProvider(activity) {
            this.$emit("activityProvider", activity)
        },
        activitySchedule(activity) {
            this.$emit("activitySchedule", activity)
        },
        changedAllow(value, changeInput) {
            if (changeInput) {
                value.allow = !value.allow
            }
            this.$emit("changedAllow")
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.custom-access-type-array {
    width: 100%;
    height: 98%;
    overflow-x: auto;
    margin: 0;

    @media all and (max-width: 768px) {
        padding-right: 20px;
    }

    table {
        width: 100%;
        position: relative;
        thead {
            border: none;
            color: $grey-darker;
            background-color: $white;
            position: sticky;
            z-index: 1;
            top: 0;
            tr {
                border-bottom: solid 1px $grey-neutral;

                th {
                    font-family: $font_avenir_heavy;
                    color: $blue-neutral;
                    font-size: $normal;
                    padding: 1em 0;
                    text-wrap: nowrap;
                    padding-right: 20px;
                    &.activity-name {
                        width: 30%;
                    }
                    &.number {
                        text-align: start;
                    }
                    &.check {
                        display: flex;
                        justify-content: flex-start;
                        gap: 5px;
                    }

                    &.mobile {
                        width: 30%;
                    }
                }
            }
        }
        tbody {
            color: $blue-dark;
            font-size: $small;
            font-family: "Avenir Heavy";
            cursor: pointer;

            &.no-activities {
                cursor: default;

                tr {
                    border-bottom: 0px solid $grey-silver !important;
                    &:hover {
                        background-color: $white;
                    }
                    td {
                        color: $pink;
                        font-family: $font_avenir_heavy;
                        border-bottom: 0px solid $grey-silver !important;
                        text-align: center;
                    }
                }
            }

            tr {
                &.add {
                    font-size: $normal;
                    color: $orange-neutral;
                    cursor: pointer;
                }
                background-color: $white;
                transition: background-color 200ms;
                height: 50px;
                border-bottom: solid 1px $grey-neutral;
                td {
                    height: 100%;
                    color: $blue-neutral;
                    &.mobile {
                        &:hover {
                            cursor: default;
                        }
                        .providerInformations {
                            height: 100%;
                            display: flex;
                            justify-content: start;
                            gap: 0.2em;

                            &:hover {
                                cursor: default;
                            }

                            p {
                                display: flex;
                                justify-content: start;
                                width: 100%;
                                margin: 0;
                            }
                        }
                    }
                    i {
                        font-size: $normal;
                        cursor: pointer;
                    }
                    svg {
                        font-size: $normal;
                        cursor: pointer;
                    }

                    &.activity-name {
                        font-family: $font-avenir-roman;
                    }
                    &.number {
                        font-size: $normal;
                        text-align: start;
                    }
                    &.check {
                        display: flex;
                        height: inherit;
                        gap: 0.5em;
                        align-items: center;
                        font-size: $normal;
                        justify-content: start;

                        p,
                        input {
                            margin: 0;
                        }

                        p {
                            font-size: $small;
                            text-align: center;
                            vertical-align: middle;
                        }
                    }
                    .allow {
                        color: $blue-bright;
                    }
                }
                &:hover {
                    background-color: $grey-light;
                }
            }
            border: none !important;
        }
    }
}
.legende {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    gap: 20px;
    div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        gap: 5px;
    }
}

.bottom-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: 20px;
    .btn-style {
        padding: 20px;
    }
}
.top-block {
    @media all and (max-width: 768px) {
        .title {
            font-size: $big;
        }
    }
}
.btn {
    @media all and (max-width: 768px) {
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}
</style>
